import type { FC } from "react";
import React, { memo, useMemo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { ToCommercialDepartment } from "../../../components/Forms/ToCommercialDepartment";
import LinkList from "../../../components/List/LinkList/LinkList";
import { cssCLSocials } from "../../../components/List/LinkList/StyledLinkList";
import { Payments } from "../../../components/Payments/Payments";
import { Button } from "../../../components/ui/Button/Button";
import { LinkVariants } from "../../../components/ui/Link/constants";
import Navigation from "../../../components/ui/Navigation/Navigation";
import { Typography } from "../../../components/ui/Typography/Typography";
import { useApp } from "../../../hooks/app";
import { Container, Row } from "../../../styles/utils/StyledGrid";
import { colors } from "../../../styles/utils/vars";
import { LinkItemType, NavType } from "../../../types/types";
import { CONTACTS, ROUTES, TITLE_SITE_RU } from "../../../utils/constants";
import { FooterContactList, FooterContent, FooterMain, FooterMainList, FooterUnder, ListGroup, ListGroupTitle, StyledFooter } from "./StyledFooter";
import { Modal } from "@/ui/Modal/Modal";
const Subscription = dynamic(((() => import("../../../components/Subscription/Subscription").then(mod => mod.Subscription)) as DynamicOptions), {
  ssr: false
});
const lists: NavType[] = [{
  title: "Компания",
  items: [{
    title: "О компании",
    path: ROUTES.about
  }, {
    title: "Магазины",
    path: ROUTES.stores
  }, {
    title: "Политика",
    path: ROUTES.policy
  }, {
    title: "Контакты",
    path: ROUTES.contacts
  }]
}, {
  title: "Информация",
  items: [{
    title: "Помощь",
    path: ROUTES.help
  }, {
    title: "Условия оплаты",
    path: ROUTES.paymentTerm
  }, {
    title: "Условия доставки",
    path: ROUTES.deliveryTerm
  }, {
    title: "Гарантия на товар",
    path: ROUTES.productWarranty
  }]
}, {
  title: "Помощь",
  items: [{
    title: "Вопрос-ответ",
    path: ROUTES.faq
  }, {
    title: "Конфиденциальность",
    path: ROUTES.agree
  }]
}];
const CONTACTS_NUMBERS: LinkItemType[] = [{
  icon: "Location",
  title: "Волгоград ул. Кольцевая 64",
  path: "https://yandex.ru/maps/-/CCUF4CG2kB",
  target: "_blank"
}];
const emailItems: LinkItemType[] = [{
  icon: "Email",
  title: "zakaz@groster.me - Отдел продаж, любые вопросы по вашим заказам",
  path: "mailto:zakaz@groster.me"
}, {
  icon: "Email",
  title: "zakupki@groster.me - Отдел закупок, для предложений от поставщиков",
  path: "mailto:zakupki@groster.me"
}, {
  icon: "Email",
  title: "development@groster.me - Отдел развития, для предложений по аренде площадей",
  path: "mailto:development@groster.me"
}, {
  icon: "Email",
  title: "info@groster.me - Ваши жалобы и предложения",
  path: "mailto:info@groster.me"
}];
export const Footer: FC = memo(() => {
  const {
    socials
  } = useApp();
  const socialsList = useMemo(() => Object.entries(socials || {}).map(([, s]) => s), [socials]);
  return <StyledFooter>
      <FooterMain>
        <Container>
          <Row>
            <FooterContent>
              <ListGroup>
                {lists.map((l, index) => <Navigation key={index} items={l.items} title={l.title} orientation={"vertical"} />)}
              </ListGroup>
              <FooterMainList>
                <ListGroupTitle>Подписка на рассылку</ListGroupTitle>
                <Subscription />
                <Payments />
              </FooterMainList>
              <FooterContactList>
                <ListGroupTitle>Наши контакты</ListGroupTitle>
                <LinkList items={CONTACTS} variant={LinkVariants.BLACK_TO_PURPLE} />
                <LinkList items={CONTACTS_NUMBERS} />
                <Modal title={"Заявка в отдел коммерции"} closeMode={"destroy"} variant={"rounded-50"} disclosure={<Button icon={"Chat"} variant={"link"} isHiddenBg isClean>
                      <Typography variant={"p12"}>
                        Отдел коммерции, для предложений от поставщиков
                      </Typography>
                    </Button>}>
                  <ToCommercialDepartment />
                </Modal>
                <LinkList items={emailItems} variant={LinkVariants.BLACK_TO_PURPLE} />
                {socialsList.length > 0 && <LinkList items={socialsList} className={cssCLSocials} />}
              </FooterContactList>
            </FooterContent>
          </Row>
        </Container>
      </FooterMain>
      <FooterUnder>
        <Row>
          <Container>
            <Typography variant={"p12"} color={colors.grayDark}>
              {new Date().getFullYear()} © {TITLE_SITE_RU}{" "}
            </Typography>
          </Container>
        </Row>
      </FooterUnder>
    </StyledFooter>;
});
Footer.displayName = "Footer";