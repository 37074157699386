import {
  Feedback,
  RequestFoundCheaper,
  RequestNotFoundNeeded,
  ResponseFaq,
  V1SubscribeCreatePayload,
} from "../../contracts"
import {
  FetcherBasePropsType,
  get,
  getAbsolutePath,
  post,
} from "@/service/fetcher"

export type RequestRecallType = {
  phone: string
  name: string
}

export const fetchFoundCheaper = (data: RequestFoundCheaper): Promise<null> =>
  post<null, RequestFoundCheaper>("/found-cheaper", data)

export const fetchNotFoundNeeded = (
  data: RequestNotFoundNeeded,
): Promise<null> => post<null, RequestNotFoundNeeded>("/not-found-needed", data)

export const fetchRecall = (data: RequestRecallType): Promise<null> =>
  post("/recall", data)

export const fetchSubscribe = (data: V1SubscribeCreatePayload): Promise<null> =>
  post("/subscribe", data)

export const fetchFeedback = (data: Feedback): Promise<null> =>
  post("/feedback", data)

export const fetchFaq = (data?: FetcherBasePropsType): Promise<ResponseFaq> =>
  get(`${getAbsolutePath(data?.server)}/faq`)

export type ToCommercialDepartmentRequestType = {
  category: string
  brand: string
  representation: string
  status: string
  fio: string
  social_brand?: string
  site_company?: string
  email: string
  phone: string
  info?: string
}
export const fetchToCommercialDepartment = (
  data: ToCommercialDepartmentRequestType,
): Promise<null> => post("/commercial-department", data)

export const fetchSendCustomEmail = (data: {
  message: string
}): Promise<null> => post("/send-custom-email", data)
